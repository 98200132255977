import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["link", "scrollContainer"];

  connect() {
    this.setActiveFromURL();
  }

  setActive(event) {
    this.linkTargets.forEach((link) => {
      link.classList.remove("border-indigo-500", "text-indigo-600");
      link.classList.add("border-transparent", "text-gray-700");
    });
    const activeLink = event.currentTarget;
    this.activateLink(activeLink);
  }

  setActiveFromURL() {
    const currentPath = window.location.pathname;
    const activeLink = this.linkTargets.find(
      (link) => link.getAttribute("href") === currentPath,
    );
    if (activeLink) {
      this.activateLink(activeLink);
    }
  }

  activateLink(link) {
    link.classList.remove(
      "border-transparent",
      "text-gray-700",
      "hover:border-indigo-300",
    );
    link.classList.add("border-indigo-500", "text-indigo-600");
  }
}
