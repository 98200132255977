import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="expand-text"
export default class extends Controller {
  static targets = ["content", "seeMoreText", "downCaret", "upCaret"];

  toggleContent() {
    this.contentTarget.classList.toggle("line-clamp-2");
    const isExpanded = !this.contentTarget.classList.contains("line-clamp-2");
    this.seeMoreTextTarget.textContent = isExpanded ? "Read less" : "Read more";
    this.downCaretTarget.classList.toggle("hidden", isExpanded);
    this.upCaretTarget.classList.toggle("hidden", !isExpanded);
  }
}
