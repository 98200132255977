import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "image",
    "defaultPreviewContainer",
    "uploadedPreviewContainer",
    "uploadedPreview",
    "removeButton"
  ]

  connect() {
    // Setup drag and drop
    this.element.addEventListener('dragover', this.handleDragOver.bind(this))
    this.element.addEventListener('drop', this.handleDrop.bind(this))
  }

  disconnect() {
    this.element.removeEventListener('dragover', this.handleDragOver.bind(this))
    this.element.removeEventListener('drop', this.handleDrop.bind(this))
  }

  promptFileSelection() {
    this.imageTarget.click()
  }

  previewImage(event) {
    const file = event.target.files[0]
    if (file) {
      this.displayPreview(file)
    }
  }

  handleDragOver(event) {
    event.preventDefault()
    event.stopPropagation()
  }

  handleDrop(event) {
    event.preventDefault()
    event.stopPropagation()

    const file = event.dataTransfer.files[0]
    if (file && file.type.startsWith('image/')) {
      this.imageTarget.files = event.dataTransfer.files
      this.displayPreview(file)
    }
  }

  displayPreview(file) {
    const reader = new FileReader()
    
    reader.onload = (e) => {
      this.uploadedPreviewTarget.src = e.target.result
      this.defaultPreviewContainerTarget.classList.add('hidden')
      this.uploadedPreviewContainerTarget.classList.remove('hidden')
    }

    reader.readAsDataURL(file)
  }

  removeImage() {
    this.imageTarget.value = ''
    this.uploadedPreviewTarget.src = ''
    this.uploadedPreviewContainerTarget.classList.add('hidden')
    this.defaultPreviewContainerTarget.classList.remove('hidden')
  }
}
