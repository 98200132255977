import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="profile-to-feed"
export default class extends Controller {
  static targets = ["link"];

  setPostId(event) {
    const profilePostId = event.currentTarget.dataset.profilePostId;

    if (profilePostId) {
      // console.log(`Setting profilePostId storage to ${profilePostId}.`);
      sessionStorage.setItem("profilePostId", profilePostId);
    }
  }

  scrollToPost(event) {
    const savedPostId = sessionStorage.getItem("profilePostId");
    const postsPath = this.linkTarget.dataset.postsPath;

    if (savedPostId) {
      event.preventDefault();
      // console.log(`Scrolling to post ${savedPostId} in feed.`);
      Turbo.visit(postsPath, { action: "replace" });
      setTimeout(() => {
        this.scrollToPostById(savedPostId);
      }, 100); // Delay the scroll to allow the page to settle
    } else {
      // console.log("No profilePostId saved, redirecting to feed path.");
      Turbo.visit(postsPath, { action: "replace" });
    }
  }

  scrollToPostById(profilePostId) {
    const postElement = document.getElementById(`post_${profilePostId}`);
    if (postElement) {
      // console.log(`Scrolling to post at offset ${postElement.offsetTop}.`);
      const offset = postElement.offsetTop;
      window.scrollTo({
        top: offset,
        behavior: "auto", // Use "auto" instead of "smooth" for instant scroll
      });
      sessionStorage.removeItem("profilePostId");
    }
  }
}
