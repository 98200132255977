import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="preview-image"
export default class extends Controller {
  static targets = ["input", "preview"]

  connect() {
    if (!this.hasPreviewTarget) {
      console.error("Preview target missing for PreviewImageController");
    }

    if (!this.hasInputTarget) {
      console.error("Input target missing for PreviewImageController");
    }
  }

  promptFileSelection() {
    this.inputTarget.click();
  }

  previewImage() {
    const input = this.inputTarget;
    const file = input.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.previewTarget.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  }
}
