import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="select-business-category"
export default class extends Controller {
  static targets = ["radio", "label", "svg", "border"];

  connect() {
    this.updateRadioState();
  }

  update() {
    this.updateRadioState();
  }

  updateRadioState() {
    this.radioTargets.forEach((radio, index) => {
      const label = this.labelTargets[index];
      const svg = this.svgTargets[index];
      const border = this.borderTargets[index];

      if (radio.checked) {
        label.classList.remove("border-gray-300");
        label.classList.add("border-indigo-600", "ring-1", "ring-indigo-600");
        svg.classList.remove("invisible");
        border.classList.add("border-indigo-600");
        border.classList.remove("border-transparent");
      } else {
        label.classList.add("border-gray-300");
        label.classList.remove(
          "border-indigo-600",
          "ring-1",
          "ring-indigo-600",
        );
        svg.classList.add("invisible");
        border.classList.add("border-transparent");
        border.classList.remove("border-indigo-600");
      }
    });
  }
}
