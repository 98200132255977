import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "canvas",
    "rpm",
    "load",
    "rpmValue",
    "loadValue",
    "audio",
    "resultEngine",
    "resultRpm",
    "resultLoad",
    "resultAngle",
  ];
  static values = {
    simulateUrl: String,
    initialEngine: Object,
  };

  connect() {
    console.log("Engine Simulator controller connected");
    console.log("Simulate URL:", this.simulateUrlValue);
    this.ctx = this.canvasTarget.getContext("2d");
    this.updateRpmValue();
    this.updateLoadValue();
    if (this.hasInitialEngineValue) {
      this.updateSimulation(this.initialEngineValue);
    }
  }

  updateRpm() {
    this.updateRpmValue();
    this.simulate();
  }

  updateLoad() {
    this.updateLoadValue();
    this.simulate();
  }

  updateRpmValue() {
    this.rpmValueTarget.textContent = `${this.rpmTarget.value} RPM`;
  }

  updateLoadValue() {
    this.loadValueTarget.textContent = `${(this.loadTarget.value * 100).toFixed(0)}%`;
  }

  simulate() {
    console.log("Simulate method called");
    const rpm = parseInt(this.rpmTarget.value);
    const load = parseFloat(this.loadTarget.value);
    console.log("Simulating with RPM:", rpm, "and Load:", load);

    fetch(this.simulateUrlValue, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
      },
      body: JSON.stringify({ simulation: { rpm, load } }),
    })
      .then((response) => {
        console.log("Response received:", response);
        return response.json();
      })
      .then((data) => {
        console.log("Data received:", data);
        if (data.error) {
          console.error("Simulation error:", data.error);
        } else {
          this.updateSimulation(data);
        }
      })
      .catch((error) => console.error("Error:", error));
  }

  updateSimulation(data) {
    const { engine, rpm, load, crankshaft_angle, piston_positions, audio_url } =
      data;
    this.drawEngine(crankshaft_angle, piston_positions);

    this.resultEngineTarget.textContent = engine;
    this.resultRpmTarget.textContent = rpm;
    this.resultLoadTarget.textContent = (load * 100).toFixed(0) + "%";
    this.resultAngleTarget.textContent =
      ((crankshaft_angle * 180) / Math.PI).toFixed(2) + "°";

    if (this.audioTarget.src !== audio_url) {
      this.audioTarget.src = audio_url;
      this.audioTarget.play();
    }
  }

  drawEngine(crankshaftAngle, pistonPositions) {
    const ctx = this.canvasTarget.getContext("2d");
    const width = this.canvasTarget.width;
    const height = this.canvasTarget.height;

    // Clear the canvas
    ctx.clearRect(0, 0, width, height);

    // Draw the engine block
    ctx.fillStyle = "#333";
    ctx.fillRect(50, 50, width - 100, height - 100);

    // Draw the crankshaft
    ctx.beginPath();
    ctx.arc(width / 2, height - 50, 30, 0, Math.PI * 2);
    ctx.fillStyle = "#666";
    ctx.fill();

    // Draw the pistons
    pistonPositions.forEach((position, index) => {
      const x = 100 + (index * (width - 200)) / pistonPositions.length;
      const y = 100 + position * (height - 200);

      ctx.fillStyle = "#999";
      ctx.fillRect(x - 20, y - 30, 40, 60);

      // Draw connecting rod
      ctx.beginPath();
      ctx.moveTo(x, y);
      ctx.lineTo(width / 2, height - 50);
      ctx.strokeStyle = "#666";
      ctx.lineWidth = 5;
      ctx.stroke();
    });

    // Draw crankshaft marker
    const markerX = width / 2 + Math.cos(crankshaftAngle) * 30;
    const markerY = height - 50 + Math.sin(crankshaftAngle) * 30;
    ctx.beginPath();
    ctx.arc(markerX, markerY, 5, 0, Math.PI * 2);
    ctx.fillStyle = "red";
    ctx.fill();
  }
}
