// app/javascript/controllers/filter_slider_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["container"];

  scrollLeft() {
    this.containerTarget.scrollBy({ left: -200, behavior: "smooth" });
  }

  scrollRight() {
    this.containerTarget.scrollBy({ left: 200, behavior: "smooth" });
  }
}
