import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["hiddenFeatures", "showMoreButton", "buttonText", "downCaret", "upCaret"]

  toggleFeatures() {
    this.hiddenFeaturesTarget.classList.toggle('hidden')
    const isExpanded = !this.hiddenFeaturesTarget.classList.contains('hidden')
    this.buttonTextTarget.textContent = isExpanded ? 'Show less' : 'Show more'
    this.downCaretTarget.classList.toggle('hidden', isExpanded)
    this.upCaretTarget.classList.toggle('hidden', !isExpanded)
  }
}
